import React  from 'react';
import { navigate } from 'gatsby';
import { check } from '../../utils/stringUtils';
import {window} from 'browser-monads';
import {
  getTagColour
} from '../../utils/selectors';

import {
  CardContainer,
  Title,
  PreviewImage,
  InnerContainer,
  ShortIntroduction,
  Tags,
  PreviewImageContainer,
  Subheading
} from './styles'

const ArticleCard = ({ title, subHeading, shortPreview, previewImage, tags, id, index, thumb_click_pdf_link }) => {
	
	const getLink = () => {
		if(thumb_click_pdf_link && thumb_click_pdf_link.url && thumb_click_pdf_link.url.length > 15) {
			window.open(thumb_click_pdf_link.url, thumb_click_pdf_link.target ? thumb_click_pdf_link.target : '_self');
			return;
		}

		navigate(`/news/${id}`);
	}

	return (
		<CardContainer key = { title.toLowerCase() } onClick = { getLink }>
			<PreviewImageContainer>
			<PreviewImage src = { previewImage } />
			</PreviewImageContainer>
			<InnerContainer>
			<Title>{ capitalizeFirstLetter(title) }</Title>
			{ subHeading && <Subheading>{ subHeading }</Subheading> }
			<ShortIntroduction>{ truncate.apply(shortPreview, [ 200, true ]) }</ShortIntroduction>
			<Tags color = { getTagColour(tags[0]) }>{ tags }</Tags>
			</InnerContainer>
		</CardContainer>
	)
};

/* TODO: Rewrite this. */
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function truncate( n, useWordBoundary ){
  if (this.length <= n) { return this; }
  var subString = this.substr(0, n-1);
  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(' '))
    : subString) + "...";
}

export default ArticleCard;

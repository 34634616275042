import React from 'react'
import { graphql } from 'gatsby'

import { getComponentsFromPrismic } from '../utils/getComponentsFromPrismic'
import {window} from 'browser-monads';
import Layout from '../layouts/layout'
import DynamicContentContainer from '../utils/dynamicContentContainer'
import ArticlesPreviewContainer from '../components/articles-preview'
import { isWide } from '../utils/responsive'
import { goToAnchor } from 'react-scrollable-anchor'
import { setTimeout } from 'window-or-global';


class Index extends React.Component {
  
	componentDidMount() {
		//   console.log(this.props.data.allPrismicHome);
		if (isWide) {
			if (typeof document !== 'undefined') {
				this.header = document.getElementsByTagName('header')[0]
				this.header.classList.add('absolute')
			}
		}

		this.checkScroll();
  }

  componentDidUpdate() {
		this.checkScroll();
  }

  checkScroll() {
	window.scrollTo(0, 0);

	setTimeout(() => {
		
		const hash = location.hash;
		if (hash && hash.indexOf('aboutus') >= 0) {
			const elements = document.querySelectorAll('main > section');
			if (elements && elements.length) {
				let rect;
				if (elements.length > 1) {
					rect = elements[1].getBoundingClientRect();
					// elements[1].scrollIntoView({ behavior: 'smooth' })
				} else {
					rect = elements[0].getBoundingClientRect();
					// elements[0].scrollIntoView({ behavior: 'smooth' })
				}

				window.scrollTo({
					top: rect.top,
					left: 0,
					behavior: 'smooth'
				});
			}
		}

	}, 500);

	
  }

  componentWillUnmount() {
    if (this.header && isWide) {
      this.header.classList.remove('absolute')
    }
  }

  render() {
    return (
      <Layout>
        <DynamicContentContainer
          name={this.constructor.displayName}
          data={getComponentsFromPrismic(this.props.data.allPrismicHome)}
        />
        <ArticlesPreviewContainer />
      </Layout>
    )
  }
}

export const query = graphql`
  query GetHomeContent {
    allPrismicHome {
      edges {
        node {
          data {
            body {
              items {
                component {
                  type
                  document {
                    ...PageHeroHeading
                    ...BasicContentContainer
                    ... on PrismicVideoEmbed {
                      data {
                        heading {
                          text
                        }
                        content {
                          html
                        }
                        embedcode {
                          html
                        }
                        layout
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Index
